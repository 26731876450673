import React, { useState, useEffect } from "react"
import Layout from '../containers/Layout/Layout'
import { Link } from 'gatsby'
import tw, { css, styled } from 'twin.macro'
import Seo from '../components/Seo'
import ServiceCard from '../components/ServiceCard'

import HeroImageOne from '../assets/images/bg-hero-1.png'
import HeroImageTwo from '../assets/images/bg-hero-2.png'
import HeroImageThree from '../assets/images/bg-hero-3.png'
import DotsBackground from '../assets/images/dots.png'
import IntroPicture from '../assets/images/intro-lady-smiling.png'

import EquipmentMaintenance from '../assets/images/equipment-maintenance.png'
import CorrosionManagement from '../assets/images/corrosion-management.png'
import AssetIntegrity from '../assets/images/asset-image.jpg'
import Procurement from '../assets/images/procurement.png'
import Automation from '../assets/images/automation.png'
import Construction from '../assets/images/services-page-construction.png'
import NewsLetter from "../components/NewsLetter"
import Partners from '../components/Partners'

import ChevronRight from '../assets/svgs/chevron-right.svg'
import ChevronLeft from '../assets/svgs/chevron-left.svg'


const HeroWrapper = styled.section`
  background-image: url(${HeroImageOne});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
  ${tw`w-full bg-no-repeat bg-center bg-cover relative`}

  &:before,
  &:after {
    ${tw`absolute w-full h-full inset-0 bg-cover bg-no-repeat bg-center`}
    background-color: rgba(0, 0, 0, 0.7);
    background-blend-mode: multiply;
    content: "";
    opacity: 0;
    transition: all 1.5s;
  }

  &:before {
    background-image: url(${HeroImageTwo});
  }

  &:after {
    background-image: url(${HeroImageThree});
  }

  ${({ secondSlide }) =>
    secondSlide &&
    css`
      &:before {
        opacity: 1;
      }
    `
  }

  ${({ thirdSlide }) =>
      thirdSlide &&
      css`
        &:after {
          opacity: 1;
        }
      `
    }


 
`

const HeroSection = styled.div`
  ${tw`max-w-screen-xl mx-auto px-5 sm:px-10 medium:px-0 relative`}
  z-index: 1000;
`

const HeroImageControlBox = styled.div`
  ${tw`flex justify-between absolute right-5 bottom-5 md:right-9 md:bottom-9 w-auto md:w-28`}
  z-index: 1000;

  & > *:first-child {
    margin-right: 10px;
  }
`

const HeroImageControl = styled.a`
  ${tw`bg-white flex items-center justify-center cursor-pointer no-underline`}
  border-radius: 3px;
  width: 30px;
  height: 30px;

  @media (min-width: 768px) {
    width: 46px;
    height: 35px;
  }
`

const HeroContentContainer = tw.section`py-36 md:py-40`;


const FirstSpan = styled.span`
  transition: opacity 1.5s;
  display: block;
  opacity: 0;
  pointer-events: none;

  ${({ firstSlide }) =>
    firstSlide &&
    css`
      opacity: 1;
    `
  }
`
const SecondSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1.5s;
  display: block;
  opacity: 0;
  pointer-events: none;

  ${({ secondSlide }) =>
    secondSlide &&
    css`
      opacity: 1;
    `
  }
`
const ThirdSpan = styled.span`
  transition: opacity 1.5s;
  position: absolute;
  top: 0; 
  left: 0;
  display: block;
  opacity: 0;
  pointer-events: none;

  ${({ thirdSlide }) =>
    thirdSlide &&
    css`
      opacity: 1;
    `
  }
`

const HeroTitle = styled.h1`
  ${tw`relative leading-tight text-9/2 sm:text-5xl md:text-brand-large text-white font-mulish font-extrabold mb-8 tracking-brand md:w-heroTitle`}

`

const HeroSubTitle = styled.p`
  ${tw`leading-snug text-xl md:text-2xl text-white font-lato mb-9 md:w-heroSubTitle relative`}
`

const WhiteCtaLink = styled(props => <Link {...props} />)`
  ${tw`bg-white text-brand-dark font-lato inline-block`}
  border-radius: 3px;
  padding: 15px 37.5px;
`;

const IntroSection = styled.section`
  ${tw`max-w-screen-xl mx-auto px-5 sm:px-10 lg:px-14 xl:px-29 py-27 flex overflow-hidden justify-between items-center`}
`

const IntroContent = tw.section`md:w-6/12 lg:w-intro-content`

const IntroImage = styled.section`
  ${tw`hidden md:block md:w-5/12 lg:w-intro-img relative`}

  ::before {
    ${tw`absolute block`}
    content: '';
    width: 132px;
    height: 146px;
    top: -32px;
    left: -42px;
    background-image: url(${DotsBackground});
    z-Index: -1;
    background-size: cover;
    background-repeat: no-repeat;
  }
`

const IntroHeading = styled.h2`
  ${tw`capitalize font-mulish text-brand-dark text-4xl big-tablet:text-9/2 lg:text-5xl font-extrabold tracking-brand leading-tight`}
  margin-bottom: 2.65625rem;
`

const IntroText = styled.p`
  ${tw`font-lato text-brand-dark text-xl leading-normal opacity-60`}
  margin-bottom: 70px;
`

const IntroCta = styled(props => <Link {...props} />)`
  ${tw`bg-brand-blue text-white font-lato inline-block`}
  box-shadow: 0px 10.6465px 21.2929px rgba(37, 74, 196, 0.2);
  border-radius: 4px;
  padding: 19.9826px 49.9565px;
`

const ServicesWrapper = styled.section`
  ${tw`bg-brand-light-blue`}
`

const ServicesSection = tw.div`max-w-screen-xl mx-auto px-5 py-10 sm:px-10 lg:px-14 xl:px-28 lg:py-20 xl:pt-20 xl:pb-30`

const ServicesSectionTitle = tw.h2`text-brand-dark mb-14 font-mulish tracking-brand text-9/2 sm:text-brand-big leading-tight font-extrabold`

const ServicesSectionCardsRow = styled.section`
  ${tw`grid grid-cols-1 big-tablet:grid-cols-2 gap-y-10 gap-x-11`}
`
const PresentationWrapper = tw.section`bg-brand-dark`;

const PresentationSection = tw.div`
  max-w-screen-xl mx-auto px-5 sm:px-10 py-24 lg:px-14 xl:px-18 md:pt-40 md:pb-32 flex flex-col md:flex-row items-center justify-between
`

const PresentationContent = tw.section`order-2 md:order-1 w-full md:w-5/12 xl:w-presentation-content`
const PresentationContentTitle = tw.h3`
  font-mulish font-extrabold text-white text-3xl sm:text-4xl big-tablet:text-9/2 mb-10 leading-tight
`
const PresentationContentText = styled.p`
  ${tw`font-lato font-normal text-white text-lg mb-10 sm:mr-8`}
  line-height: 1.2222;
`

const PresentationVideo = styled.section`
  ${tw`order-1 md:order-2 w-full md:w-6/12 xl:w-presentation-video h-auto md:h-96 relative mb-16 md:mb-0`};
`

const BreakLine = tw.br`hidden md:block`

const PresentationVideoPlayer = styled.iframe`
  ${tw`w-full h-full`}
  border-radius: 12.2495px;
`


const IndexPage = () => {
  const [count, setCount] = useState(0);

  function forwardAnimation() {
    if (count < 2) {
      setCount(count + 1);
    }
    
    if (count === 2) {
      setCount(0);
    }
  }

  function backwardAnimation() {
    if (count === 0) {
      setCount(2);
    }

    if (count > 0) {
      setCount(count - 1);
    }
  }
  
  useEffect(() => {
    const interval = setInterval(function(){
      if (count < 2) {
        setCount(count + 1);
      }
      
      if (count === 2) {
        setCount(0);
      }
    }, 4000);
    return () => {
      clearInterval(interval)
    }
  },[count])

  return (
    <Layout>
      <Seo title="Home" />
      {/* Hero Section */}
      <HeroWrapper firstSlide={count === 0} secondSlide={count === 1} thirdSlide={count === 2}>
        <HeroSection>
          <HeroContentContainer>
            <HeroTitle>
              <FirstSpan firstSlide={count === 0}>Engineering solutions with a difference.</FirstSpan>
              <SecondSpan secondSlide={count === 1}>Remarkable Expertise & Professionalism</SecondSpan>
              <ThirdSpan thirdSlide={count === 2}>Quality Service Delivery</ThirdSpan>
            </HeroTitle>
            <HeroSubTitle>
              <FirstSpan firstSlide={count === 0}>
                "There are a lot of companies that provide engineering solutions but there aren’t so many companies that are capable of providing safe and quality innovative engineering solutions like we do."
              </FirstSpan>
              <SecondSpan secondSlide={count === 1}>"Our engineering style & perspective are focused on customers' needs"</SecondSpan>
              <ThirdSpan thirdSlide={count === 2}>"Quality is an intrinsic part of our services and our commitment to this, is made of steel"</ThirdSpan>
            </HeroSubTitle>
            <WhiteCtaLink to="/contact-us">Let's get started</WhiteCtaLink>
          </HeroContentContainer>
        </HeroSection>
        <HeroImageControlBox>
          <HeroImageControl onClick={backwardAnimation}>
            <ChevronLeft />
          </HeroImageControl>
          <HeroImageControl onClick={forwardAnimation}>
            <ChevronRight />
          </HeroImageControl>
        </HeroImageControlBox>
      </HeroWrapper>

      {/* Intro Section */}
      <IntroSection>
        <IntroContent>
          <IntroHeading>Decades of cumulative experience in the bag!</IntroHeading>
          <IntroText>A team of professionals with decade of experience cutting through various sectors including, construction, procurement, project management and oil & Gas.</IntroText>
          <IntroCta to="/contact-us">Have a walk with us</IntroCta>
        </IntroContent>
        <IntroImage>
          <img src={IntroPicture} alt="A lady smiling" />
        </IntroImage>
      </IntroSection>

      {/* Services Section */}
      <ServicesWrapper>
        <ServicesSection>
          <ServicesSectionTitle>Our <BreakLine /> Services</ServicesSectionTitle>
          <ServicesSectionCardsRow>
            <ServiceCard 
              cardImageSrc={EquipmentMaintenance}
              cardName="Equipment Maintenance"
              cardText="We work with our clients to develop suitable preventive and corrective maintenance schedules for all mechanical equipment to keep your business's equipment in reliable working condition."
              cardLink="/services/equipment-maintenance"
            />
            <ServiceCard 
              cardImageSrc={CorrosionManagement}
              cardName="Corrosion Management"
              cardText="Our corrosion management strategy is structured to influence and continually improve the economic outcome of our client’s asset by ensuring cost effective selection of materials, monitoring and chemical treatments."
              cardLink="/services/corrosion-management"
            />
            <ServiceCard 
              cardImageSrc={AssetIntegrity}
              cardName="Asset Integrity Management"
              cardText="We are in cognisance that plant integrity, safety, and reliability are major concerns to all plant operators and managers. We have continued to excel in providing unrivalled services to our clients through programs that incorporates design, maintenance, inspection, process, operations, and management concepts."
              cardLink="/services/asset-integrity-management"
            />
            <ServiceCard 
              cardImageSrc={Construction}
              cardName="Construction"
              cardText="We offer specialized wide range of engineering designs and construction services. We are committed to maintaining high standards in our projects."
              cardLink="/services/construction"
            />
            <ServiceCard 
              cardImageSrc={Procurement}
              cardName="Procurement & Man Power Supply"
              cardText="We render highly efficient procurement consulting services, intuitive strategic sourcing technologies, and data-backed market intelligence. We have practical field experience necessary to ensure successful delivery as we realize that successful procurement is driven by dedicated effort."
              cardLink="/services/procurement"
            />
            <ServiceCard 
              cardImageSrc={Automation}
              cardName="Automation & Instrumentation"
              cardText="We specialize in industrial process automation & software, electrical & instrumentation construction, plant & industrial safety system, field instrumentation & control, equipment calibration, flowmeters and power system. We provide comprehensive and tailor-made service concepts for all Motorized valves and instrumentation product range."
              cardLink="/services/automation"
            />
          </ServicesSectionCardsRow>
        </ServicesSection>
      </ServicesWrapper>

      {/* Presentation Section */}
      <PresentationWrapper>
        <PresentationSection>
          <PresentationContent>
            <PresentationContentTitle>
              We turn simple ideas into amazing realities
            </PresentationContentTitle>
            <PresentationContentText>
              We have consistently continued to respond to this objective and keeping pace with new innovation and applications in engineering technology.
            </PresentationContentText>
            <WhiteCtaLink to="/contact-us">Let's get started</WhiteCtaLink>
          </PresentationContent>
          <PresentationVideo>
            <PresentationVideoPlayer src="https://www.youtube.com/embed/BS0P8jgvM70" title="Manz video on Youtube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></PresentationVideoPlayer>
          </PresentationVideo>
        </PresentationSection>
      </PresentationWrapper>

      {/* Trusted Partners */}
      <Partners />
      

      {/* Newsletter Section */}
      <NewsLetter />

    </Layout>
  )
 
}

export default IndexPage
